import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const emailLink = <a style={{ color: '#0b3c7b' }} href="mailto:rose@roseasprey.com">rose@roseasprey.com</a>;

const IndexPage = () => (
  <Layout>
    <SEO title="Rose Asprey | Cello Teacher" keywords={[`cello`, `cello teacher`, `bath`]} />
    <p>Currently accepting new students</p>
    <p>
      Reach out via email: {emailLink}
    </p>
  </Layout>
)

export default IndexPage
